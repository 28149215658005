import { render, staticRenderFns } from "./LandingCommunity.vue?vue&type=template&id=6e1b3a90&scoped=true&"
import script from "./LandingCommunity.vue?vue&type=script&lang=js&"
export * from "./LandingCommunity.vue?vue&type=script&lang=js&"
import style0 from "./LandingCommunity.vue?vue&type=style&index=0&id=6e1b3a90&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e1b3a90",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommunityBadge: require('/tmp/build_b2fe7c28/components/community/CommunityBadge.vue').default})
