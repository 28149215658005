const blogs = [
  {
    link: '/blog/eraofchangeinfashion',
    image: 'https://ufswebsitefotos.s3.eu-central-1.amazonaws.com/blog/blog_gesine_650.jpg',
    topic: 'fashion',
    date: new Date(2021, 2, 16),
    headline: 'THE ERA OF CHANGE IN FASHION',
    description: 'Die Chance für einen nachhaltigen Wandel der Modeindustrie, die durch Umweltverschmutzung, Ressourcenverschwendung und hohe CO2-Emissionen weltweit den zweitgrößten negativen Beitrag zur Belastung der Umwelt und der globalen Lebensqualität trägt, war nie größer.',
    tags: ['fashion', 'environment'],
    readingTime: '2 min',
    name: 'Gesine Glück'
  },
  {
    link: '/blog/comecloser',
    image: 'https://ufswebsitefotos.s3.eu-central-1.amazonaws.com/blog/sara_blog_hero_700x394.jpg',
    topic: 'marketing',
    date: new Date(2021, 2, 16),
    headline: 'COME CLOSER',
    description: 'Weniger Blabla und mehr Bam - warum leere Werbeversprechen nicht mehr ziehen und man mit einer gut erzählten Geschichte den Erfolg einer Kampagne einschlagend verändern kann.',
    tags: ['content', 'marketing'],
    readingTime: '8 min',
    name: 'Sara Krupper'
  },
  {
    link: '/blog/communityintro',
    image: 'https://ufswebsitefotos.s3.eu-central-1.amazonaws.com/blog/sara_blog_hero_700x394.jpg',
    topic: 'marketing',
    date: new Date(2021, 2, 16),
    headline: 'COME CLOSER',
    description: 'Weniger Blabla und mehr Bam - warum leere Werbeversprechen nicht mehr ziehen und man mit einer gut erzählten Geschichte den Erfolg einer Kampagne einschlagend verändern kann.',
    tags: ['content', 'marketing'],
    readingTime: '8 min',
    name: 'André Berger'
  },
  {
    link: '/blog/ufsglobalcommunity',
    image: 'https://ufswebsitefotos.s3.eu-central-1.amazonaws.com/blog/blog_andre_500x320.jpg',
    topic: 'community',
    date: new Date(2021, 2, 16),
    headline: 'THE UFS GLOBAL COMMUNITY',
    description: 'Ich glaube, wenn Kreativität mit globalen Fragen verschmilzt, dann kannst Du die Welt zusammenbringen (Virgil Abloh)',
    tags: ['community'],
    readingTime: '2 min',
    name: 'André Berger'

  },
  {
    link: '/blog/nachhaltigkeit',
    image: 'https://ufswebsitefotos.s3.eu-central-1.amazonaws.com/blog/blog_kathrin_danner_portrait.jpg',
    topic: 'sustainability',
    date: new Date(2021, 2, 16),
    headline: 'Nachhaltigkeit - von der Linear zur Circular Economy',
    description: 'Ein Spagat zwischen Ökologie Ökonomie und sozialen Aspekten.',
    tags: ['sustainability'],
    readingTime: '4 min',
    name: 'Kathrin Danner'
  },
  {
    link: '/blog/hamcollection',
    image: 'https://ufswebsitefotos.s3.eu-central-1.amazonaws.com/blog/hamcollection/hamcollection_portrait.jpg',
    topic: 'New Talents',
    date: new Date(2021, 8, 6),
    headline: 'Die Entstehung der Kollektion Fashion by HAM',
    description: 'Ein Erfahrungsbericht zum Modul Konzeption und Markteinführung einer Fashionkollektion der Fachhochschule für angewandtes Management unter Leitung von André Berger und Gesine Glück',
    tags: ['generationZ', 'fashion', 'marketing'],
    readingTime: '3 min',
    name: 'Kim Geike'
  },
  {
    link: '/blog/generationZ',
    image: 'https://ufswebsitefotos.s3.eu-central-1.amazonaws.com/blog/generationz/generationz_portrait.jpg',
    topic: 'Targeting',
    date: new Date(2021, 8, 6),
    headline: 'Generation Z - Eine Selbstanalyse',
    description: 'Wer ist die Generation Z und wofür steht sie? Kann Mode ihren Werten Ausdruck verleihen?',
    tags: ['genz', 'sokrates', 'fashion', 'marketing'],
    readingTime: '3 min',
    name: 'Ann-Kathrin Kuhfuss'
  },
  {
    link: '/blog/design-furniture-meets-fashion',
    image: 'https://ufswebsitefotos.s3.eu-central-1.amazonaws.com/blog/design-furniture-meets-fashion/Moebelkonzept_por.jpg',
    topic: 'COMMUNITY',
    date: new Date(2021, 12, 1),
    headline: 'Design furniture meets fashion',
    description: 'Styles come and go. Good design is a language, not a style (Massimo Vignelli)',
    tags: ['interior', 'fashion', 'cooperation'],
    readingTime: '5 min',
    name: 'Klaus Pomella'
  }
];

export default blogs;
