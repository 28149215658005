
import LandingIntroAlt from '~/components/page-components/landingpage/LandingIntroAlt.vue';
import LandingBlog from '~/components/page-components/landingpage/LandingBlog.vue';
import LandingCommunity from '~/components/page-components/landingpage/LandingCommunity.vue';

import ReadyToTalk from '~/components/page-components/ReadyToTalk.vue';
import LandingServiceAlt from '~/components/page-components/landingpage/LandingServiceAlt.vue';
import LandingNavigationFinal from '~/components/page-components/landingpage/LandingNavigationFinal.vue';

export default {
  components: { ReadyToTalk, LandingCommunity, LandingBlog, LandingIntroAlt, LandingServiceAlt, LandingNavigationFinal },
  data () {
    return {

    };
  },
  mounted () {
    this.$store.commit('closeMenu');
  }
};
