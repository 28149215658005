
import VueSlickCarousel from 'vue-slick-carousel';
import IconArrowRight from '~/components/icons/IconArrowRight.vue';
import IconChevron from '~/components/icons/IconChevron.vue';

export default {
  components: { VueSlickCarousel, IconChevron, IconArrowRight },
  data () {
    return {
      current: 0
    };
  },
  computed: {
    controlColor () {
      return this.current <= 1 ? 'black' : 'white';
    }
  },
  methods: {
    updateSlide (_o, n) {
      this.current = n;
    },
    nextSlide () {
      this.$refs.slider.next();
    },
    prevSlide () {
      this.$refs.slider.prev();
    }
  }
};
