
export default {
  props: {
    topic: {
      type: String,
      required: true
    },
    date: {
      type: Date,
      required: true
    },
    headline: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    tags: {
      type: Array,
      required: true
    },
    readingTime: {
      type: String,
      default: '1 min'
    }
  }
};
