import { render, staticRenderFns } from "./BlogListPreviewItem.vue?vue&type=template&id=f20761c0&"
import script from "./BlogListPreviewItem.vue?vue&type=script&lang=js&"
export * from "./BlogListPreviewItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BlogPreview: require('/tmp/build_b2fe7c28/components/blog/BlogPreview.vue').default})
