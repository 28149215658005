
export default {
  props: {
    img: {
      type: String,
      required: true
    },
    version: {
      type: Number,
      default: 0,
      validator: (val) => {
        return val >= 0 && val <= 3;
      }
    }
  },
  computed: {
    pos () {
      if (this.version === 0) {
        return 'top: 15px; left: 20px';
      } else if (this.version === 1) {
        return 'top: -55px; right: -10px';
      } else if (this.version === 2) {
        return 'top: -60px; left: 10px;';
      } else if (this.version === 3) {
        return 'top: -30px; left: 20px';
      } else {
        return 'top: -15px; left: -40px';
      }
    }
  }
};
