
import BlogPreview from './BlogPreview.vue';

export default {
  components: { BlogPreview },
  props: {
    link: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: true
    },
    version: {
      type: Number,
      required: true
    },
    topic: {
      type: String,
      default: 'topic'
    },
    date: {
      type: Date,
      default: () => new Date()
    },
    headline: {
      type: String,
      default: 'headline'
    },
    description: {
      type: String,
      default: 'Sunt nulla adipisicing veniam sit ad veniam fugiat et fugiat. Fugiat occaecat esse commodo eiusmod adipisicing laboris dolore irure et in exercitation laboris. Proident nisi deserunt fugiat ea. Est dolore sint est eu do.'
    },
    tags: {
      type: Array,
      default: () => ['tag', 'tag', 'tag']
    },
    readingTime: {
      type: String,
      default: '1 min'
    }
  }

};
