
import BlogList from '~/components/blog/BlogList.vue';
import BlogListPreviewItem from '~/components/blog/BlogListPreviewItem.vue';
import IconArrowRight from '~/components/icons/IconArrowRight.vue';
import bloginfo from '~/helpers/bloginfo.js';

export default {
  components: { IconArrowRight, BlogList, BlogListPreviewItem },
  data () {
    return {
      eoc: bloginfo[0],
      cc: bloginfo[1]
    };
  }

};
