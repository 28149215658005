import { render, staticRenderFns } from "./BlogPreview.vue?vue&type=template&id=c6b146ca&scoped=true&"
import script from "./BlogPreview.vue?vue&type=script&lang=js&"
export * from "./BlogPreview.vue?vue&type=script&lang=js&"
import style0 from "./BlogPreview.vue?vue&type=style&index=0&id=c6b146ca&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6b146ca",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BlogPreviewImage: require('/tmp/build_b2fe7c28/components/blog/BlogPreviewImage.vue').default,BlogPreviewText: require('/tmp/build_b2fe7c28/components/blog/BlogPreviewText.vue').default})
